import store from "@/store/store";

export const apis = {
  meadowCloud: getMeadowCloudBaseAPIPath(),
};

function getMeadowCloudBaseAPIPath() {
  if (
    !window.location.host ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("yellow-cliff") ||
    window.location.host.includes("blue-cliff") ||
    window.location.host.includes("test") ||
    window.location.host.includes("staging")
  ) {
    return store.state.appSettings.meadowCloudApiHost;
  }

  //production
  return "https://www.meadowcloud.co/api/v1";
}

async function request<TResponse>(
  url: string,
  errMsg?: string,
  config?: RequestInit
): Promise<TResponse> {

  if (config) {
    config.headers = {
      "Content-Type": "application/json",
      "From-UI": "true",
    };
    config.credentials = "include";

    if(store.state.impersonateuid){
      config.headers["impersonateuid"] = store.state.impersonateuid;
    }
  }

  let response!: Response;
  try {
    response = await fetch(url, config);
  } catch (exception) {
    
    if (
      apis.meadowCloud.includes("localhost") &&
      window.location.host.includes("localhost")
    ) {
      console.warn(
        "Hi developer! It looks like you are trying to hit the localhost server. If you want to develop fully locally, make sure you are running the Meadow.Cloud project, with azurite, etc. Or if you simply want to program against staging.meadowcloud.com, you can do that too. Just uncomment the line '//return \"https://staging.meadowcloud.dev/api\"' in the local section of the getMeadowCloudBaseAPIPath method in the api.ts file."
      );
      
    }
  }

  if (
    response.status >= 400 &&
    response.status < 500 &&
    (errMsg || response.statusText)
  ) {
    const text = await response.text();
    //TODO, find a nice way to present to the user
    console.error(response.statusText || text || errMsg);
    if (text) return text as any;
  }

  return await response.json();
}

export const api = {
  token: "",
  get: <TResponse>(url: string, errMsg?: string) =>
    request<TResponse>(url, errMsg, {
      method: "GET",
    }),

  // Using `extends` to set a type constraint:
  post: <TBody extends BodyInit, TResponse>(
    url: string,
    body: TBody,
    errMsg?: string
  ) =>
    request<TResponse>(url, errMsg, {
      method: "POST",
      body: JSON.stringify(body),
    }),

  put: <TBody extends BodyInit, TResponse>(
    url: string,
    body: TBody,
    errMsg?: string
  ) =>
    request<TResponse>(url, errMsg, {
      method: "PUT",
      body: JSON.stringify(body),
    }),

  delete: <TBody extends BodyInit, TResponse>(
    url: string,
    body: TBody,
    errMsg?: string
  ) =>
    request<TResponse>(url, errMsg, {
      method: "DELETE",
      body: JSON.stringify(body),
    }),
};
