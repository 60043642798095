import { apis } from "@/api/api";
import router from "@/router";

export function getCookieValue(cookieName: string): string | null {
  const name = `${encodeURIComponent(cookieName)}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1);
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function oktaSignIn(returnUrl?: string) {
  router.push("signin");
  let _returnUrl = returnUrl ?? window.location.href;
  window.location.href = `${apis.meadowCloud}/auth/signin?redirectUrl=${_returnUrl}`;
}
